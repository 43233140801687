import projektleiter from '../downloads/Inserat2024Payrollspezialist.pdf'
//import informatiker from '../downloads/Inserat2024TechnicalConsultant.pdf'
import lehrling from '../downloads/LehrstelleEntwicklerDigitalesBusinessEFZ2025.pdf'
//import lehrling2 from '../downloads/Lehrling2023.pdf'

export const jobs = [
    // Entwickler / Consultant ausblenden
    /*{
        title: 'Entwickler:in / Consultant für Schnittstellen und Prozesse',
        text: 'Eintritt nach Vereinbarung',
        file: informatiker,
        fileType: 'PDF'
    },*/
    // Wirtschaftsinformatiker / HR-Projektleiter ausblenden
    {
        title: 'Payrollspezialist:in / Abacus Projektleiter:in',
        text: 'Eintritt nach Vereinbarung',
        file: projektleiter,
        fileType: 'PDF'
    },
    //Lehrstellen ausblenden
    {
        title: 'Lehrstelle als Entwickler:in digitales Business EFZ',
        text: 'Start August 2025',
        file: lehrling,
        fileType: 'PDF'
    },
  /*{
        title: 'Lehrstelle als Informatiker/in Applikationsentwicklung EFZ',
        text: 'Lehrbeginn Sommer 2023',
        file: lehrling2,
        fileType: 'PDF'
    }*/
]