import React from 'react'
import Layout from '../components/layout'

import { jobs } from '../conf/jobs'

import pdf from '../images/pdf.png'

const renderJobs = () => {
    return jobs.map((job, index) => {
        return (
            <div className="post" key={"post_" + index}>
                <a href={job.file} target="_blank" rel="noopener noreferrer"><img src={pdf} alt="pdf" /></a>
                <div className="job-info">
                    <h3>{job.title}</h3>
                    <h5>{job.text}</h5>
                </div>
            </div>
        )
    })
}

const JobsForm = () => (
    <Layout>
        <section id="jobs" className="section-wrapper">
            <header className="section-header">
                <h3>Jobs</h3>
            </header>
            <h5>Wir suchen...</h5>
            <p>Zur Verstärkung unseres Teams suchen wir...</p>
            {renderJobs()}
        </section>
    </Layout>
)


export default JobsForm
